import server from '@/request';

const prefix = '/xiaozi-seller/wireless/after';

// 售后列表(分页)
export const queryPage = (data = {}) => {
  return server.post(`${prefix}/queryPage`, data);
};

// 采购售后数量统计
export const queryStateInfoBySeller = (data = {}) => {
  return server.post(`${prefix}/queryStateInfoBySeller`, data);
};

// 修改采购售后状态
export const editState = (data = {}) => {
  return server.post(`${prefix}/editState`, data);
};

// 查看售后详情
export const view = (data = {}) => {
  return server.post(`${prefix}/view`, data);
};

// 退货收货列表
export const queryLogisticsPage = (data = {}) => {
  return server.post(`${prefix}/queryLogisticsPage`, data);
};

// 退货收货统计
export const queryRefundStateInfo = (data = {}) => {
  return server.post(`${prefix}/queryRefundStateInfo`, data);
};

// 确认退货收货
export const sure = (data = {}) => {
  return server.post(`${prefix}/sure`, data);
};

// 查看确认收货详情
export const details = (data = {}) => {
  return server.post(`${prefix}/newDetails`, data);
};

// 物流信息查询
export function queryLogisticsInfo (data) {
  return server.post(`${prefix}/queryLogisticsInfo`, data);
}

export function returnedMaterialList (data) {
  return server.post(`${prefix}/queryOptMaterialSerialInfosBySeller`, data);
}
