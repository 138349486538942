export const executionStatusGroup = [
  {
    label: '全部',
    value: '',
    en: 'allNum',
    count: 0,
  },
  {
    label: '待收货',
    value: '1',
    en: 'stayReceivNum',
    count: 0,
  },
  {
    label: '已收货',
    value: '2',
    en: 'receivNum',
    count: 0,
  },
  {
    label: '部分收货',
    value: '3',
    en: 'partNum',
    count: 0,
  },
];

export const logisticsStatusOptions = [
  {
    label: '未签收',
    value: 1,
  },
  {
    label: '部分签收',
    value: 2,
  },
  {
    label: '已签收',
    value: 3,
  },
];

