<template>
  <div class="service-detail">
    <content-block title="基本信息">
      <el-descriptions :column="3" size="normal">
        <el-descriptions-item label="销售合同编号">{{
          detailInfo.pcCode | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="销售订单号">{{
          detailInfo.orderCode | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后申请单号">{{
          detailInfo.afterCode | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="客户抬头">{{
          detailInfo.unit | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="客户联系人">{{
          detailInfo.salesmanName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{
          detailInfo.salesmanPhone | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后类型">{{
          detailInfo.typeName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后原因">{{
          detailInfo.reason | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后申请状态">{{
          detailInfo.stateName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="退货收货信息">{{
          detailInfo.detailAddress | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="售后创建时间">{{
          detailInfo.createTime | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
          detailInfo.remark | setEmpty
        }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block title="退货信息">
      <div v-for="(i, index) in detailInfo.goodsSerialList" :key="index + i.logisticsCode">
        <el-row type="flex" style="margin-bottom: 0.5em">
          <el-col>{{ `物流公司：${i.logisticsCompany || "--"}` }}</el-col>
          <el-col>{{ `物流单号：${i.logisticsCode || "--"}` }}</el-col>
          <el-col>{{
            `物流状态：${
              i.logisticsState === 1
                ? "未签收"
                : i.logisticsState === 2
                ? "部分签收"
                : i.logisticsState === 3
                ? "已签收"
                : "--"
            }`
          }}</el-col>
        </el-row>
        <vxe-grid
          ref="xGrid"
          auto-resize
          resizable
          border
          align="center"
          highlight-current-row
          highlight-hover-row
          show-overflow
          show-footer
          :columns="columnList"
          :data="i.goodsSerialDetailList"
          :footer-method="footerMethod"
          :merge-footer-items="footerItems"
        >
          <template slot="empty">
            <el-empty :image-size="100" description="暂无数据"></el-empty>
          </template>
        </vxe-grid>
      </div>
    </content-block>
  </div>
</template>
<script>
import { details } from '@/api/service';
import setTableCols from './configs';

export default {
  name: 'return_detail',
  props: {
    id: {
      type: String,
    },
  },
  filters: {
    setEmpty (val) {
      return val ? val : '--';
    },
  },
  data () {
    return {
      detailInfo: {
        goodsSerialList: [],
      },
      columnList: setTableCols.call(this, 'detail'),
      footerItems: [{ row: 0, col: 1, rowspan: 1, colspan: 13 }],
    };
  },
  created () {
    this.getDetail();
  },
  methods: {
    getDetail () {
      details({ id: this.id }).then(({ body }) => {
        if (body.directType === 2) {
          body.goodsSerialList = body.goodsSerialList || [];

          body.goodsSerialList.forEach(v => {
            v.logisticsState += 1;
          });
        }

        body.typeName
          = body.type === 1
            ? '仅退货'
            : body.type === 2
              ? '仅退款'
              : body.type === 3
                ? '退货退款'
                : '';
        body.stateName
          = body.state === 1
            ? '待审核'
            : body.state === 2
              ? '售后处理中'
              : body.state === 3
                ? '已完成'
                : body.state === 4
                  ? '已驳回'
                  : body.state === 5
                    ? '已关闭'
                    : '';

        body.detailAddress = `${body.provinceName || ''}${body.cityName || ''}${
          body.areaName || ''
        }${body.address || ''}`;

        body.goodsSerialList = body.goodsSerialList || [{}];

        if (!body.goodsSerialList.length) {
          body.goodsSerialList = [{}];
        }

        this.detailInfo = body;
      });
    },
    footerMethod ({ data }) {
      if (!data.length) {
        return [];
      }

      let totalAmount = 0;
      let totalPrice = 0;

      data.forEach((v) => {
        totalAmount += 1;
        totalPrice += Number(v.purchPrice * 100 * 1);
      });

      return [['合计', `数量：${totalAmount}, 总金额：${totalPrice / 100}`]];
    },
  },
};
</script>
<style lang="scss" scoped>
.return-detail {
  .common-font {
    color: #909399;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
