import { setList } from './listConfig';
import { setDetail } from './detailConfig';

export default function setTableCols (type = 'list') {
  const configs = {
    'list': setList.call(this),
    'detail': setDetail.call(this),
  };

  configs[type].forEach(v => {
    if (!v.minWidth) {
      let title = v.label || v.title;

      v.minWidth = `${title.length * 16 + (v.sortable ? 30 : 20)}px`;
    }

    v.tooltip = true;
  });

  return configs[type];
}
