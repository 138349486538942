import {
  logisticsStatusOptions,
} from '@/model/serviceReturns';

function convertToName (enums, value) {
  let title = '--';

  for (let v of enums) {
    if (Number(v.value) === Number(value)) {
      title = v.label;
      break;
    }
  }

  return title;
}

export function setList () {
  const tableCols = [
    {
      type: 'expand',
      minWidth: '60px',
      slots: {
        content: ({ row }, h) => {
          return [
            h('vxe-grid', {
              style: {
                'z-index': 999,
              },
              props: {
                align: 'center',
                resizable: true,
                autoResize: true,
                showOverflow: true,
                maxHeight: '500px',
                size: 'small',
                data: row.expandData,
                columns: [
                  {
                    title: '物流公司',
                    field: 'logisticsCompany',
                  },
                  {
                    title: '物流单号',
                    field: 'logisticsCode',
                  },
                  {
                    title: '物流状态',
                    field: 'logisticsState',
                    formatter: ({ row }) => {
                      return convertToName(logisticsStatusOptions, row['logisticsState']);
                    },
                  },
                ],
              },
            }),
          ];
        },
      },
    },
    {
      title: '退货收货状态',
      field: 'refundState',
      formatter: ({ row }) => {
        return row.refundState === 1 ? '待收货' : row.refundState === 2 ? '已收货' : row.refundState === 3 ? '部分收货' : '--';
      },
    },
    {
      title: '退货申请单号',
      field: 'afterCode',
    },
    {
      title: '客户名称',
      field: 'unit',
    },
    {
      title: '退货时间',
      field: 'createTime',
    },
    {
      title: '销售单号',
      field: 'orderCode',
    },
    {
      title: '退货仓库',
      field: 'warehouseName',
    },
    {
      title: '发货类型',
      field: 'type',
      formatter: ({ row }) => {
        return row.type === 1 ? '直发' : '非直发';
      },
    },
    {
      title: '操作',
      minWidth: '80px',
      slots: {
        default: ({ row }, h) => {
          let actions = [h('span', {
            class: 'contract-option',
            on: {
              click: () => {
                this.operate('detail', row);
              },
            },
          }, '查看')];

          if (row.refundState === 1) { // 1-> 合同待审核
            actions.push(h('span', {
              class: 'contract-option',
              on: {
                click: () => {
                  this.operate('confirm', row);
                },
              },
            }, '确认收货'));
          }

          return actions;
        },
      },
    },
  ];

  return tableCols;
}
